.add-button {
    margin-bottom: 10px;
    padding: 5px 20px;
    background-color: #9ed3e3;
    border: 1px solid #84abb7;
    border-radius: 5px;
    font-size: larger;
    font-weight: bolder;
}

.add-button:hover {
    background-color: #8cbac9;
}

.table-container {
    overflow-x: scroll;
    overflow-y: visible;
    position: relative;
    padding-bottom: 100px;
    margin-bottom: -100px;
}

.buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.page-buttons {
    margin-left: 5%;
}

.page-buttons > button {
    background-color: #9ed3e3;
    border: 1px solid #84abb7;
    border-radius: 5px;
    margin-left: 5px;
}

.page-buttons > button:hover {
    background-color: #8cbac9;
}

.page-buttons > span {
    padding: 0 10px;
}