.navbar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin: 30px 0 10px 0;
}

.top-content {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    color: #197de1;
}

.pages {
    width: 100%;
    padding-bottom: 5px;
    border-bottom: 1px solid #b7b7b7;
}

.pages > * {
    margin-right: 10px;
    text-decoration: none;
    color: black;
    padding: 0 5px 5px 5px;
}

.pages > *:hover {
    color: #197de1;
}

.selected {
    border-bottom: 3px solid #197de1;
    color: #197de1;
}

.prod-color {
    color: #FD0D15;
}

.gamma-color {
    color: #FD9C0D;
}