.model-form {
    display: table;
}

.model-form-header {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    text-align: right;
}

.x-button {
    margin-left: 90%;
}

.x-button:hover {
    cursor: pointer;
    color: blue;
}

.model-form-field {
    display: table-row;
    border-top: 1px solid lightgray;
}

.model-form-field > * {
    display: table-cell;
    width: 70%;
    margin: 10px 0;
}

.model-form-field > label {
    text-align: right;
    width: 30%;
}

.model-form-field > button {
    width: 35%;
    background-color: #f2f2f2;
    border: 1px solid black;
    margin: 10px 1px 0 0;
}

.model-form-field > button:hover {
    background-color: lightgray;
}

.checkbox {
    width: 20px;
    height: 20px;
}