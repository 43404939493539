.model-buttons {
    display: flex;
}

.model-buttons > button {
    margin: 5px;
    padding: 10px 20px;
}

.modal {
    display: block;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4);
}

.modal-content {
    background-color: #fefefe;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 40%;
}

.update-button {
    background-color: #a5e7bb;
    border: 1px solid #84b795;
    border-radius: 5px;
}

.update-button:hover {
    background-color: #85c29a;
}

.copy-button {
    background-color: #9ed3e3;
    border: 1px solid #7ea9b6;
    border-radius: 5px;
}

.copy-button:hover {
    background-color: #8cbac9;
}

.delete-button {
    background-color: #e97777;
    border: 1px solid #ba5d5d;
    border-radius: 5px;
}

.delete-button:hover {
    background-color: #c76565;
}

.dashboard-button {
    background-color: #f2f2f2;
    border: 1px solid #c6c6c6;
    border-radius: 5px;
}

.dashboard-button:hover {
    background-color: #d9d6d6;
}