.antisignature-form {
    display: table;
}

#aggregatedBy {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.aggregated-by-container {
    padding: 0 5rem 0 5rem;
}

.antisignature-feature-field {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    width: 100%;
    flex-grow: 1;
    margin-bottom: 0.2rem;
}

.antisignature-feature-field > input {
    min-width: 0;
    margin: 0.2rem;
    flex: 1;
}

.antisignature-form-header {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    text-align: right;
}

.x-button {
    margin-left: 90%;
}

.x-button:hover {
    cursor: pointer;
    color: blue;
}

.antisignature-form-field {
    display: table-row;
    border-top: 1px solid lightgray;
}

.antisignature-form-field > * {
    display: table-cell;
    width: 70%;
    margin: 10px 0;
}

.antisignature-form-field > label {
    text-align: right;
    width: 30%;
}

.antisignature-form-field > button {
    width: 35%;
    background-color: #f2f2f2;
    border: 1px solid black;
    margin: 10px 1px 0 0;
}

.antisignature-form-field > button:hover {
    background-color: lightgray;
}

select {
    word-wrap: break-word;
    word-break: break-word;
    min-width: 0;
}

.note{
    font-size: 0.8rem;
    column-span: all;
    margin-top: 10px;
    align-self: center;
}