.open-button {
    width: 100%;
    height: 100%;
    text-align: end;
    font-size: small;
}

.content {
    position: relative;
    z-index: 1;
    background-color: aliceblue;
    padding: 10px;
}

label {
    padding-right: 10px;
}

.update-buttons {
    display: flex;
    justify-content: space-evenly;
}