.table-container {
    overflow-x: scroll;
    overflow-y: visible;
    position: relative;
    padding-bottom: 100px;
    margin-bottom: -100px;
}

.page-buttons {
    margin: 20px 0;
    text-align: right;
}

.page-buttons > button {
    background-color: #9ed3e3;
    border: 1px solid #84abb7;
    border-radius: 5px;
    margin-left: 5px;
}

.page-buttons > button:hover {
    background-color: #8cbac9;
}

.page-buttons > span {
    padding: 0 10px;
}