.open-button {
    width: 100%;
    height: 100%;
    text-align: end;
    font-size: small;
}

.content {
    position: absolute;
    z-index: 1;
    background-color: #f2f2f2;
    border: 1px solid black;
    padding: 10px;
}

label {
    padding-right: 10px;
    margin-bottom: 10px;
}

.calendars {
    display: flex;
}

.calendar {
    display: flex;
    flex-direction: column;
    margin: 10px;
}

.update-buttons {
    display: flex;
    justify-content: flex-end;
}

.update-buttons > button {
    margin: 10px 5px;
    border: 1px solid black;
    border-radius: 5px;
}