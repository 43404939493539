.clickable {
    margin-right: 5px;
    padding: 5px;
}

.clickable:hover {
    cursor: pointer;
    outline: 1px solid steelblue;
}

th, tbody > * {
    vertical-align: middle;
    text-align: center;
    padding: 5px;
}

.filters, .filters > td, .filters > td > * {
    height: 36px;
    padding: 0;
    margin: 0;
    min-width: 100px;
}

.filters > td > * {
    width: 100%;
}

tr, th {
    background-color: #f2f2f2 !important;
}

thead > tr > th {
    background-color: #f7faff !important;
}