body {
    margin: 0 50px;
    background-color: #fafafa;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button, input {
    background-color: white;
    border: 1px solid #eef0f2;
}

input:read-only {
    background-color: lightgray;
}

*::-webkit-scrollbar {
    border: none;
    background-color: transparent;
}

*{
    scrollbar-color: #404040b3 transparent;
}